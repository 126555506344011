import {useEffect, useState} from 'react';
import type {LottiePlayer} from 'lottie-web';

export default function useLottieWeb() {
  const [lottie, setLottie] = useState<LottiePlayer>();

  useEffect(() => {
    // need to load lottie this way to prevent SSR issues
    import('lottie-web').then((lottieImport) => {
      setLottie(lottieImport.default);
    });
  }, []);

  return lottie;
}
